<template>
    <div class="box">
        <div class="box-header">
            <h3 class="box-title">Seznam zákazníků</h3>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="input-group input-group-sm" style="width: 100%">
                        <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                    </div>
                </div>
                <div class="col-lg-8">
                    <router-link :to="{ name: 'CustomerAdd' }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-plus"></i> Přidat zákazníka</router-link>
                </div>
            </div>
            <br>
            <div class="table-responsive no-padding" style="width: auto; overflow: auto">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="customer in getCustomers" :key="customer.customerid">
                            <td style="cursor: pointer" @click="makeCurrentCus(customer.customerid)"><a>{{customer.company_name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentMnt(customer.contactid)"><a>{{customer.contact_fullname}}</a></td>
                            <td></td>
                            <td>{{customer.street}}</td>
                            <td>{{customer.city}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="!loaded" class="overlay">
            <i class="fa fa-spinner fa-spin"></i>
        </div>
    </div>
</template>

<script>
const name = 'Customers';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {
        return {
            filter: '',
            loaded: false,
        };
    },
    
    computed: 
    {
        ...mapGetters(['getAllPages', 'allCustomers', 'getCurrentPage']),
        getCustomers() {
            var allCustomers = this.allCustomers

            if (!allCustomers.length)
            {
                return []
            }
            
            // Filtr provedeme na vícero položkách auta -> možnost vyhledávání jak podle jména, tak podle VIN, atd..
            return allCustomers.filter(item => {
                return (
                    this.$helpers.filterCheck(this.filter, item.company_name)
                );
            })
        }
    },
    methods: {
        ...mapActions([
            'makeActiveTab', 'addTab', 'fetchCustomers', 
            'makeCurrentCus', 'makeCurrentMnt', 'makeCurrentIssue'
        ])
    },
    created()
    {
        this.fetchCustomers().then(() => {
            this.loaded = true;
        });

        const page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(page);
        this.makeActiveTab(page);

        this.headColumns = [
            { name: 'Název' },
            { name: 'Kontaktní osoba' },
            { name: 'Fakturační osoba' },
            { name: 'Ulice' },
            { name: 'Město' },
        ];
    },
}
</script>